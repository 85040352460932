export const SLOT_LARGE_PRODUCT_MESSAGE_FRAGMENT = `
  fragment slotLargeProductMessageFragment on LargeProduct {
    uid
    slots: _slots(filter: { code: "message_feature" }) {
      uid
      code
      quantity
      strategy
      type
    }
  }
`;
